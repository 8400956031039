<template>
  <VueFinalModal
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content rounded-20">
      <div class="modal-header">
        <p class="heading-section-4 fw-normal modal-title fs-5">
          Пожаловаться на {{ title }}
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="confirm"
        />
      </div>
      <div class="modal-body">
        <label class="col-12 mt-20">
          <span class="fw-bold">
            Текст жалобы
          </span>
          <small>({{ getCountChar }}/{{ maxChar }})</small>
          <textarea
            v-model="$v.message.$model"
            class="form-control rounded-20"
            :class="$v.message.$error ? 'is-invalid' : ''"
            rows="7"
          />
          <div
            v-show="$v.message.$error"
            class="invalid-feedback"
          >
            {{ $v.message.maxLength.$invalid ? 'Длина текста не должна превышать 120 символов' : 'Введите текст' }}
          </div>
        </label>
        <div class="d-flex justify-content-end mt-20 gap-20">
          <button
            class="btn btn-book"
            @click="confirm"
          >
            Отмена
          </button>
          <button
            class="btn btn-primary"
            @click="submit"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'

const { $toast } = useNuxtApp();

const emits = defineEmits(['confirm']);

const props = defineProps({
  title: { type: String, required: true },
  source: { type: Number, required: true },
  status: { type: Number, default: 0 },
  entity: { type: String, required: true },
});

//-----VARIABLES-----\\
const maxChar = 120;

//-----STATE-----\\
const message = ref<string|null>(null);
const $v = useVuelidate({
  message: { maxLength: maxLength(maxChar), required },
}, {
  message
});

//-----COMPUTED-----\\
const getCountChar = computed<number>(() => {
  return message.value === null ? 0 : message.value.length;
})

//-----METHODS-----\\
async function submit() {
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }

  await mainFetch('complaints', {
    method: 'POST',
    body: {
      source: props.source,
      status: props.status,
      entity: props.entity,
      message: message.value
    }
  }).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code === 200) {
      $toast.success('Жалоба отправлена');
      confirm();
    }
  });
}

function confirm() {
  emits('confirm');
}

</script>
<style scoped lang="scss">
//@import 'vue-toastification/src/scss/index';
</style>