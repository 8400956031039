<template>
  <div
    class="video_player position-relative d-flex flex-column h-100 w-100 overflow-hidden"
    :class="isVideoView ? 'bg-dark' : 'bg-white'"
  >
    <div
      class="w-100 px-10 pt-10 d-flex justify-content-between"
      :class="{
        'position-absolute': isVideoView,
        invisible: isHideBack,
      }"
      style="z-index: 2"
    >
      <AppButton
        v-if="!onlyStep || arrowShow"
        :variant="BtnVariant.Link"
        is-icon
        :class="(isVideoView ? 'text-white' : 'text-dark') + (arrowShow ? '' : ' lg-invisible')"
        @click="close"
      >
        <svg
          class="flip-horizontal h-100 w-100"
        >
          <use xlink:href="/public/icons/main.svg#arrow-right" />
        </svg>
      </AppButton>
      <div
        v-if="!onlyStep"
        class="d-flex justify-content-center w-100 gap-10"
      >
        <button
          v-if="getAnswersList.additions !== undefined && !isArchive"
          class="btn-tab btn btn-link p-0 text-decoration-none position-relative pb-5 shadow-none"
          :class="(isVideoView ? 'text-white' : 'text-dark') + (activeTab === PlayerTabs.AdditionVideos ? ' active' : '')"
          @click="activeTab = PlayerTabs.AdditionVideos"
        >
          Доп. вопросы
        </button>
        <button
          v-if="customTabTitle !== undefined"
          class="btn-tab btn-tab__dark btn btn-link p-0 text-decoration-none position-relative pb-5 shadow-none"
          :class="(isVideoView ? 'text-white' : 'text-dark') + (activeTab === PlayerTabs.Custom ? ' active' : '')"
          @click="activeTab = PlayerTabs.Custom"
        >
          {{ customTabTitle }}
        </button>
        <button
          class="btn-tab btn btn-link p-0 text-decoration-none position-relative pb-5 shadow-none"
          :class="(isVideoView ? 'text-white' : 'text-dark') + (activeTab === PlayerTabs.Videos ? ' active' : '')"
          @click="activeTab = PlayerTabs.Videos"
        >
          Видео
        </button>
        <button
          class="btn-tab btn-tab__dark btn btn-link p-0 text-decoration-none position-relative pb-5 shadow-none"
          :class="(isVideoView ? 'text-white' : 'text-dark') + (activeTab === PlayerTabs.Details ? ' active' : '')"
          @click="activeTab = PlayerTabs.Details"
        >
          Детали
        </button>
      </div>
      <div class="col-1" />
      <AppButton
        class="ms-lg-auto flex-shrink-0 d-none"
        :variant="BtnVariant.Tertiary"
        :active="isShowQuestion"
        is-icon
        :class="isVideoView ? '' : 'invisible'"
        @click="isShowQuestion = !isShowQuestion"
      >
        <svg class="h-100 w-100">
          <use xlink:href="/public/icons/main.svg#contact-support" />
        </svg>
      </AppButton>
    </div>
    <div class="position-relative h-100 w-100 overflow-hidden">
      <div
        v-show="isVideoView"
        class="position-relative h-100 w-100"
      >
        <span
          v-if="getAnswersList.additions !== undefined"
          v-show="activeTab === PlayerTabs.AdditionVideos && getAnswersList.additions.length === 0"
          class="position-absolute fw-bold text-white top-50 translate-middle-y w-100 text-center px-10"
        >
          Ожидание записи ответов на вопросов от кандидата
        </span>
        <div
          class="position-absolute h-100 top-0 start-0"
          style="z-index: 1; width: 15%;"
          @click="changeVideo(activeAnswer.index - 1, activeTab === PlayerTabs.AdditionVideos ? 'additions' : 'default')"
        />
        <template v-for="(group, groupType) in getAnswersList">
          <VideoView
            v-for="(answer, i) in group"
            v-show="activeAnswer.index === i && ((activeTab === PlayerTabs.Videos && groupType === 'default') || (activeTab === PlayerTabs.AdditionVideos && groupType === 'additions'))"
            :is-assembled="answer.src !== null"
            :src="answer.src"
            :is-play="activeAnswer.index === i && activeAnswer.type === groupType && !isChangeTab && !isCloseModal && !isFinishVideos"
            :is-next-play="activeAnswer.index === i && activeAnswer.type === groupType && !isChangeTab && !isCloseModal && !isFinishVideos"
            :is-reflection="isReflection"
            :is-blob="answer.isBlob??false"
            :wrap-height="wrapHeight"
            :wrap-width="wrapWidth"
            show-play-btn
            @play="() => { isChangeTab = false; isCloseModal = false; isFinishVideos = false}"
            @timeline="updateTime"
            @is-finish="nextVideo(groupType)"
          />
        </template>
        <div
          class="position-absolute h-100 top-0 end-0"
          style="z-index: 1; width: 15%;"
          @click="changeVideo(activeAnswer.index + 1, activeTab === PlayerTabs.AdditionVideos ? 'additions' : 'default')"
        />
      </div>
      <div
        v-if="!onlyStep"
        class="h-100 w-100 overflow-auto pb-43"
      >
        <div
          v-show="activeTab === PlayerTabs.Details"
          class="w-100 p-10"
        >
          <div class="d-flex align-items-center mt-10 w-100 mb-20">
            <div
              class="me-24"
              style="height:80px; width:80px; flex-shrink: 0;"
            >
              <img
                v-if="getImg !== null"
                class="h-100 w-100 rounded-20"
                style="object-fit: contain;"
                :src="getImg"
                :alt="getTitle"
              >
            </div>
            <div>
              <div class="heading-text-1 fw-bold">
                {{ getName }}
              </div>
              <div
                class="w-100 heading-text-1"
                :class="{'fw-bold' : vacancy === undefined}"
              >
                {{ getSurname }}
              </div>
            </div>
          </div>
          <div
            v-if="getSocial"
            class="mb-20"
          >
            <div class="heading-text-1 fw-bold">
              Контакты
            </div>
            <div
              class="d-flex"
              :class="{'d-none' : getPhone === null}"
            >
              <a
                class="text-decoration-none text-primary overflow-hidden text-nowrap"
                :class="!isShowPhone ? ' phone-hidden' : null"
                :href="'tel:' + getPhone"
              >
                {{ getPhone }}
              </a>
              <button
                v-show="!isShowPhone"
                class="ms-10 text-gray p-0 border-0 bg-white"
                @click="isShowPhone = true"
              >
                Показать телефон
              </button>
            </div>
            <div
              class="d-flex my-5"
              :class="{'d-none' : getSocial.email === undefined}"
            >
              <a
                class="text-decoration-none text-primary overflow-hidden text-nowrap"
                :class="!isShowEmail ? ' phone-hidden' : null"
                :href="'mailto:' + getSocial.email"
              >
                {{ getSocial.email }}
              </a>
              <button
                v-show="!isShowEmail"
                class="ms-10 text-gray p-0 border-0 bg-white"
                @click="isShowEmail = true"
              >
                Показать E-mail
              </button>
            </div>
            <div class="d-flex flex-wrap mt-10">
              <a
                class="d-flex me-20 align-items-center text-decoration-none text-black"
                :class="{'d-none' : getSocial.whatsapp === undefined}"
                title="Whatsapp"
                :href="`https://wa.me/${getSocial.whatsapp}`"
                target="_blank"
              >
                <svg
                  height="25"
                  width="25"
                  class="me-5"
                >
                  <use xlink:href="/public/icons/whatsapp.svg#whatsapp" />
                </svg>
                <span>Whatsapp</span>
              </a>
              <a
                class="d-flex me-20 align-items-center text-decoration-none text-black"
                :class="{'d-none' : getSocial.telegram === undefined}"
                title="Telegram"
                :href="`https://t.me/+${getSocial.telegram}`"
                target="_blank"
              >
                <svg
                  height="25"
                  width="25"
                  class="me-5"
                >
                  <use xlink:href="/public/icons/telegram-blue.svg#telegram-blue" />
                </svg>
                <span>Telegram</span>
              </a>
              <a
                class="d-flex align-items-center text-decoration-none text-black"
                :class="{'d-none' : getSocial.viber === undefined}"
                title="Должно быть установлено приложение Viber"
                :href="`viber://chat?number=+${getSocial.viber}`"
                target="_blank"
              >
                <svg
                  height="25"
                  width="25"
                  class="me-5"
                >
                  <use xlink:href="/public/icons/viber.svg#viber" />
                </svg>
                <span>Viber</span>
              </a>
            </div>
          </div>
          <div class="mb-20">
            <div class="heading-text-1 fw-bold">
              Город
            </div>
            {{ getCity }}
          </div>
          <template v-if="summary !== undefined">
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Возраст
              </div>
              {{ summary.age }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Профессии
              </div>
              {{ getProfession }}
            </div>
            <div
              v-if="isResponse"
              class="mb-20"
            >
              <div class="heading-text-1 fw-bold">
                Дата рождения
              </div>
              {{ new Date(summary.dateBirth * 1000).toLocaleString(['ru-RU', 'en-US'], {day: '2-digit', month: '2-digit', year: 'numeric'}) }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Опыт работы в профессии
              </div>
              {{ summary.experienceInProfession.title }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Общий стаж, лет
              </div>
              {{ summary.experience }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Желаемый график работы
              </div>
              {{ summary.schedules.map(val => val.name).join(', ') }}
            </div>
          </template>
          <template v-else-if="vacancy !== undefined">
            <div
              v-if="vacancy.address !== null"
              class="mb-20"
            >
              <div class="heading-text-1 fw-bold">
                Адрес
              </div>
              {{ vacancy.address }}
            </div>
            <div
              v-if="vacancy.metroStation.length > 0"
              class="mb-20"
            >
              <div class="heading-text-1 fw-bold">
                Ближайшее метро
              </div>
              <div class="d-flex align-items-center">
                <span
                  class="rounded-circle me-5"
                  style="height: .5em; width: .5em;"
                  :style="{ 'background-color': '#' + vacancy.metroStation[0].line.color }"
                />
                {{ vacancy.metroStation[0].name }}
              </div>
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Опыт работы в данной должности
              </div>
              {{ vacancy.experience.title }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                График
              </div>
              {{ vacancy.schedules.map(val => val.name).join(', ') }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                Зарплата
              </div>
              {{ getSalary }}
            </div>
            <div class="mb-20">
              <div class="heading-text-1 fw-bold">
                О вакансии
              </div>
              <div style="white-space: pre-line">
                {{ vacancy.description }}
              </div>
            </div>
          </template>
          <div v-if="chatId !== undefined" class="w-100 text-center">
            <AppButton
              :to="`/chats?id=${chatId}`"
            >
              Открыть чат
            </AppButton>
          </div>
        </div>
        <div
          v-show="activeTab === PlayerTabs.Custom"
          class="w-100 p-10"
        >
          <slot name="customTab" />
        </div>
      </div>
      <VideoPlayerActionBar
        v-if="!onlyStep"
        :id="getId"
        class="position-absolute bottom-0 start-0 justify-content-between mt-10 w-100 p-10"
        :class="isVideoView ? 'd-none' : 'd-flex'"
        :url="getUrl"
        :is-vacancy="isVacancy"
      >
        <slot name="actionBar" />
      </VideoPlayerActionBar>
    </div>
    <div
      v-show="isVideoView"
      class="position-absolute bottom-0 w-100 text-white p-10 rounded-20"
      style="z-index: 1;"
    >
      <div
        v-if="!onlyStep"
        class="mb-10"
      >
        <div class="title fw-bold">
          {{ getTitle }}
        </div>
        <div v-show="isVacancy">
          {{ getProfession }}
        </div>
      </div>
      <div
        class="d-flex align-items-end mb-10"
        style="font-size: 16px"
      >
        <div v-show="isShowQuestion">
          {{ getQuestion }}
        </div>
        <div
          v-show="!isHiddenTimer"
          class="ms-auto ps-5"
          style="padding-right: 45px"
        >
          {{ getDuration }}
        </div>
      </div>
      <div class="d-flex gap-5">
        <button
          v-for="(_, i) in getAnswersList[(activeTab === PlayerTabs.AdditionVideos && getAnswersList.additions !== undefined ? 'additions' : 'default')].length"
          class="position-relative btn border-0 rounded-20 p-0 flex-grow-1 bg-white overflow-hidden"
          style="height: 4px;"
          @click="changeVideo(i, activeTab === PlayerTabs.AdditionVideos ? 'additions' : 'default')"
        >
          <span
            class="timeline position-absolute top-0 d-block bg-primary h-100 w-100 rounded-20"
            :class="i <= activeAnswer.index ? 'bg-primary' : 'bg-white'"
            style="left: -100%"
            :style="i < activeAnswer.index ? 'transform: translateX(100%)' : i === activeAnswer.index ? `transform: translateX(${timeline[activeAnswer.type]}%)` : 'transform: translateX(0)'"
          />
        </button>
      </div>
      <VideoPlayerActionBar
        v-if="!onlyStep"
        :id="getId"
        :url="getUrl"
        :is-vacancy="isVacancy"
      >
        <slot name="actionBar" />
      </VideoPlayerActionBar>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { VideoData } from "~/entities/videoData";
import type { Vacancy } from "~/entities/vacancy";
import type { Summary } from "~/entities/Summary";
import VideoPlayerActionBar from "~/components/VideoPlayer/VideoPlayerActionBar.vue";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { PlayerTabs } from "~/composables/enums/PlayerTabs";
import type { VideoPlayerAnswers } from "~/entities/VideoPlayerAnswers";
import { usePhoneNormalize } from "~/composables/utils/phoneNormalize";
import type { PropType } from "vue";

const props = defineProps({
  answers: { type: Array as PropType<VideoPlayerAnswers|VideoData[]>, default: () => [] },
  isVacancy: { type: Boolean, default: false },
  isResponse: { type: Boolean, default: false },
  vacancy: { type: Object as PropType<Vacancy>, default: undefined },
  summary: { type: Object as PropType<Summary>, default: undefined },
  isHideBack: { type: Boolean, default: false },
  isSent: { type: Boolean, default: false },
  onlyStep: { type: Boolean, default: false },
  defaultTab: { type: Number as PropType<PlayerTabs>, default: 0 },
  wrapHeight: { type: Number, default: undefined },
  wrapWidth: { type: Number, default: undefined },
  reset: { type: Boolean, default: false },
  arrowShow: { type: Boolean, default: false },
  isReflection: { type: Boolean, default: false },
  customTabTitle: { type: String, default: undefined },
  isArchive: { type: Boolean, default: false },
  isHiddenTimer: { type: Boolean, default: false },
  chatId: { type: String, default: undefined },
});

const emits = defineEmits(['close', 'request']);

//-----STATE-----\\
const isShowPhone = ref<boolean>(false);
const isShowEmail = ref<boolean>(false);
const isShowQuestion = ref<boolean>(true);
const activeAnswer = ref<Object>({
  index: 0,
  type: 'default'
});
const timeline = ref<Object>({
  additions: 0,
  default: 0
});
const currentTime = ref<Object>({
  additions: 0,
  default: 0
});
const activeTab = ref<PlayerTabs>(props.defaultTab);
const isChangeTab = ref<boolean>(true);
const isCloseModal = ref<boolean>(true);
const isFinishVideos = ref<boolean>(false);

//-----COMPUTED-----\\
const getTitle = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.company.name;
  } else if (props.summary !== undefined) {
    return props.summary.client.name + ' ' + props.summary.client.surname;
  }
  return '';
});

const getImg = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.company.logo;
  } else if (props.summary !== undefined) {
    return props.summary.client.photo;
  }
});

const getSocial = computed(() => {
  let tmp = null;
  if (props.vacancy !== undefined) {
    tmp = props.vacancy.social;
  } else if (props.summary !== undefined) {
    tmp = props.summary.social;
  }

  if (tmp !== null && Object.keys(tmp).length > 0) {
    return tmp;
  }

  return [];
});

const getName = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.profession.title;
  } else if (props.summary !== undefined) {
    return props.summary.client.name;
  }
});

const getSurname = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.company.name;
  } else if (props.summary !== undefined) {
    return props.summary.client.surname;
  }
});

const getCity = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.city.title;
  } else if (props.summary !== undefined) {
    return props.summary.city.title;
  }
});

const getPhone = computed(() => {
  let tmp = null;
  if (props.vacancy !== undefined) {
    tmp = props.vacancy.social.phone??null;
  } else if (props.summary !== undefined) {
    tmp = props.summary.client.phone;
  }

  if (tmp !== null) {
    return usePhoneNormalize(tmp);
  }

  return null;
});

const getProfession = computed(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.profession.title;
  } else if (props.summary !== undefined) {
    return props.summary.professions.map(val => val.title).join(', ');
  }
  return '';
});

const getSalary = computed<string|null>(() => {
  if (props.vacancy === undefined || (props.vacancy.salary.min === null && props.vacancy.salary.max === null)) {
    return 'Не указана';
  }

  let salary = '';
  if (props.vacancy.salary.min !== null) {
    salary += 'от ' + props.vacancy.salary.min.toLocaleString(['ru-RU', 'en-US']) + ' ';
  }
  if (props.vacancy.salary.max !== null) {
    salary += 'до ' + props.vacancy.salary.max.toLocaleString(['ru-RU', 'en-US']) + ' ';
  }
  return salary + 'руб';
});

const getUrl = computed<string>(() => {
  if (props.vacancy !== undefined) {
    return '/vacancies/' + props.vacancy.id;
  } else if (props.summary !== undefined) {
    return '/summaries/' + props.summary.id;
  }
  return '';
});

const getId = computed<string>(() => {
  if (props.vacancy !== undefined) {
    return props.vacancy.id;
  } else if (props.summary !== undefined) {
    return props.summary.id;
  }
  return '';
});

const getDuration = computed<string>(() => {
  const tmpAnswers = getAnswersList.value[activeTab.value === PlayerTabs.AdditionVideos && getAnswersList.value.additions !== undefined ? 'additions' : 'default'];
  // if (props.onlyStep || props.answers.length === 0) {
  // if (props.onlyStep || tmpAnswers.length === 0) {
  if (props.isHiddenTimer || tmpAnswers.length === 0) {
    return '0:00';
  }
  let duration;

  if (activeTab.value === PlayerTabs.AdditionVideos && getAnswersList.value.additions !== undefined) {
    duration = currentTime.value.additions * -1;
  } else {
    duration = currentTime.value.default * -1;
  }
  // for (let i = activeAnswer.value; i < props.answers.length; i++) {
  for (let i = activeAnswer.value.index; i < tmpAnswers.length; i++) {
    // duration += props.answers[i].duration;
    duration += tmpAnswers[i].duration;
  }

  const minutes = duration < 60 ? 0 : Math.floor(duration / 60);
  const seconds = Math.round(duration - minutes * 60);

  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
});

const getQuestion = computed<string>(() => {
  const tmpAnswers = getAnswersList.value[activeTab.value === PlayerTabs.AdditionVideos && getAnswersList.value.additions !== undefined ? 'additions' : 'default'];

  if (tmpAnswers !== undefined && tmpAnswers.length > 0) {
    return tmpAnswers[activeAnswer.value.index].question;
  } else {
    return '';
  }
});

const getAnswersList = computed<VideoPlayerAnswers>(() => {
  if (props.answers.default === undefined) {
    return {
      default: props.answers,
    } as VideoPlayerAnswers;
  } else {
    return (props.answers as VideoPlayerAnswers);
  }
});

const isVideoView = computed<boolean>(() => {
  return activeTab.value === PlayerTabs.Videos || activeTab.value === PlayerTabs.AdditionVideos;
});

watch(() => props.answers, () => {
  changeVideo(0, activeTab.value === PlayerTabs.AdditionVideos ? 'additions' : 'default');
});
watch(() => activeTab.value, (val) => {
  isChangeTab.value = true;
  activeAnswer.value.index = 0;

  if (val === PlayerTabs.AdditionVideos) {
    activeAnswer.value.type = 'additions';
  } else if (val === PlayerTabs.Videos) {
    activeAnswer.value.type = 'default';
  }

  timeline.value[activeAnswer.value.type] = 0;
  currentTime.value[activeAnswer.value.type] = 0;
});

//-----METHODS-----\\
function close() {
  isCloseModal.value = true;
  activeAnswer.value.index = 0;

  emits('close');
}
function changeVideo(index: number, type: string) {
  if ((activeAnswer.value.index === index && activeAnswer.value.type === type) || index < 0 || index >= props.answers.length) {
    return;
  }
  isChangeTab.value = false;
  isCloseModal.value = false;
  activeAnswer.value.index = index;
  activeAnswer.value.type = type;

  timeline.value[activeAnswer.value.type] = 0;
  currentTime.value[activeAnswer.value.type] = 0;
}
function nextVideo(groupType: string) {
  let length;
  if (groupType === 'additions') {
    length = props.answers.additions.length;
  } else if (props.answers.additions !== undefined) {
    length = props.answers.default.length;
  } else {
    length = props.answers.length;
  }
  if (activeAnswer.value.index + 1 > length - 1) {
    activeAnswer.value.index = 0;
    activeAnswer.value.type = groupType;
    timeline.value[activeAnswer.value.type] = 0;
    currentTime.value[activeAnswer.value.type] = 0;
    isFinishVideos.value = true;

    return;
  }

  changeVideo(activeAnswer.value.index + 1, groupType);
}
function updateTime(line: number, time: number) {
  if (!isCloseModal.value) {
    timeline.value[activeAnswer.value.type] = line;
    currentTime.value[activeAnswer.value.type] = time;
  } else {
    timeline.value[activeAnswer.value.type] = 0;
    currentTime.value[activeAnswer.value.type] = 0;
  }
}
</script>

<style scoped lang="scss">
.btn-tab.active:after {
  position: absolute;
  content: ' ';
  width: 70%;
  height: 2px;
  left: 15%;
  bottom: 0;
  background: var(--bs-white);
}
.btn-tab.btn-tab__dark.active:after {
  background: var(--bs-dark);
}
.phone-hidden {
  text-overflow: ellipsis !important;
  width: 90px !important;
}
</style>