<template>
  <div class="d-flex justify-content-between mt-10">
    <div
      v-click-outside="() => isDropVisibility = false"
      class="dropdown d-inline-block me-15"
    >
      <AppButton
        :variant="BtnVariant.Tertiary"
        is-icon
        @click="isDropVisibility = !isDropVisibility"
      >
        <svg class="h-100 w-100">
          <use xlink:href="/public/icons/main.svg#more" />
        </svg>
      </AppButton>
      <ul
        class="dropdown-menu position-absolute bottom-100 mb-5"
        :class="{ show: isDropVisibility }"
      >
        <li v-show="url !== undefined">
          <NuxtLink
            class="dropdown-item"
            :to="url"
            title="Открыть в новой вкладке"
            target="_blank"
          >
            Открыть в новой вкладке
          </NuxtLink>
        </li>
        <li v-show="url !== undefined">
          <button
            class="dropdown-item"
            title="Скопировать ссылку на вакансию"
            @click="copyLink"
          >
            Копировать ссылку
          </button>
        </li>
        <li>
          <button
            class="dropdown-item"
            title="Пожаловаться"
            @click="complain"
          >
            Пожаловаться
          </button>
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import {BtnVariant} from "~/composables/enums/BtnVariant";
import { useModal } from "vue-final-modal";
import ComplaintModel from "~/components/modal/ComplaintModal.vue";

const config = useRuntimeConfig();
const { $toast } = useNuxtApp();

//-----STATE-----\\
const useComplaintModal = useModal({
  component: ComplaintModel,
  attrs: {
    onConfirm() {
      useComplaintModal.close();
    }
  }
});

//-----PROPS-----\\
const props = defineProps({
  id: { type: String, default: undefined },
  url: { type: String, default: '' },
  isVacancy: { type: Boolean, default: false },
});

//-----VARIABLES-----\\

//-----STATE-----\\
const isDropVisibility = ref<boolean>(false);
const { copy, copied } = useClipboard();

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function copyLink() {
  copy(config.public.protocol + '://' + config.public.domain + props.url);
  if (copied) {
    $toast.success('Ссылка скопирована');
    isDropVisibility.value = false;
  }
}

function complain() {
  useComplaintModal.open();
  useComplaintModal.options.attrs.title = props.isVacancy ? 'вакансию' : 'резюме';
  useComplaintModal.options.attrs.source = props.isVacancy ? 1 : 0;
  useComplaintModal.options.attrs.entity = props.id;
  useComplaintModal.options.attrs.status = 0;
}

</script>